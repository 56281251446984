import { CircularProgress, Container, Paper, Box } from "@mui/material"
import { Navigate, Route, Routes } from "react-router-dom"
import { ConditonalWrapper, useWindowSize } from "./utils/utils"
import NotFound from "./routes/NotFound"
import Login from "./routes/Login"
import BottomTabs from "./components/BottomTabs"
import { useAppDispatch, useAppSelector } from "./app/hooks"
import {
  buildExerciseRecords,
  buildWorkouts,
  initializeCoreData,
  selectApp,
  userAuthStateChanged,
} from "./features/appSlice"
import { useEffect } from "react"
import { auth, collection, db, onAuthStateChanged } from "./app/firebase"
import ProtectedRoute from "./routes/Protected"
import WorkoutDetail from "./routes/WorkoutDetail"
import Workouts from "./routes/Workouts"
import Workout from "./routes/Workout"
import { onSnapshot } from "firebase/firestore"

function App() {
  const size = useWindowSize()
  const app = useAppSelector(selectApp)

  const dispatch = useAppDispatch()

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      dispatch(
        userAuthStateChanged(
          user !== null
            ? { id: user.uid, name: user.displayName, email: user.email }
            : null,
        ),
      )
      if (user !== null) {
        dispatch(initializeCoreData())
          .then(() => {
            const uid = user?.uid
            const workouts = collection(db, `users/${uid}/workouts`)
            onSnapshot(workouts, (snapshot) => {
              dispatch(
                buildWorkouts(
                  snapshot.docs.map((doc) => {
                    return {
                      id: doc.id,
                      data: doc.data(),
                    }
                  }),
                ),
              )
            })
            const exerciseRecords = collection(
              db,
              `users/${uid}/exerciseRecords`,
            )
            onSnapshot(exerciseRecords, (snapshot) => {
              dispatch(
                buildExerciseRecords(
                  snapshot.docs.map((doc) => {
                    return {
                      id: doc.id,
                      data: doc.data(),
                    }
                  }),
                ),
              )
            })
          })
          .catch((error) => {
            console.error(error)
          })
      }
    })
  }, [])

  return (
    <ConditonalWrapper
      condition={size.width !== undefined && size.width > 600}
      wrapper={(children) => (
        <Container
          style={{
            maxWidth: 750,
          }}
        >
          {children}
        </Container>
      )}
    >
      <>
        <Paper
          style={{
            marginTop: size.width !== undefined && size.width > 600 ? 30 : 0,
            minHeight:
              size.width !== undefined && size.width > 600 ? "650px" : "100vh",
          }}
        >
          <div
            style={{
              overflow: "auto",
              height: `calc(100vh - ${
                size.width != undefined && size.width > 600 ? 105 : 60
              }px)`,
            }}
          >
            {app.state === "loading" ? (
              <Box
                sx={{
                  display: "flex",
                  height: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <Routes>
                <Route path="login" element={<Login />} />
                <Route
                  path="/"
                  element={
                    <ProtectedRoute>
                      <Workouts />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="workout-detail"
                  element={
                    <ProtectedRoute>
                      <WorkoutDetail />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="workout"
                  element={
                    <ProtectedRoute>
                      {app.currentWorkout ? <Workout /> : <Navigate to="/" />}
                    </ProtectedRoute>
                  }
                />
                <Route path="*" element={<NotFound />} />
              </Routes>
            )}
          </div>
        </Paper>
        {app.user ? <BottomTabs /> : null}
      </>
    </ConditonalWrapper>
  )
}

export default App
