import { Close } from "@mui/icons-material"
import { IExercise } from "../../@types/workout"
import {
  Box,
  Icon,
  IconButton,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material"

type Props = {
  idx: number
  exercise: IExercise | null
  handleRemove: (idx: number) => void
}

const ExerciseCardList = ({ idx, exercise, handleRemove }: Props) => {
  return (
    <>
      {exercise !== null ? (
        <Stack direction="row" spacing={1} alignItems="center" padding={1}>
          <Typography variant="h6">{exercise.title}</Typography>
          <Box component="div" sx={{ flexGrow: 1 }} />
          <Tooltip title="Remove">
            <IconButton onClick={() => handleRemove(idx)}>
              <Close />
            </IconButton>
          </Tooltip>
        </Stack>
      ) : (
        <Skeleton />
      )}
    </>
  )
}

export default ExerciseCardList
