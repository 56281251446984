import { Close } from "@mui/icons-material"
import {
  Box,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
} from "@mui/material"

export interface FilterDialogProps {
  open: boolean
  selectedMuscleGroups: string[]
  muscleGroups: string[]
  selectedEquipment: string[]
  eqipment: string[]
  selectedDifficulty: string[]
  difficulty: string[]
  onClose: () => void
  handleMuscleGroupsFilterChange: (value: string[]) => void
  handleEquipmentFilterChange: (value: string[]) => void
  handleDifficultyFilterChange: (value: string[]) => void
}

function FilterDialog(props: FilterDialogProps) {
  const {
    onClose,
    handleMuscleGroupsFilterChange,
    handleEquipmentFilterChange,
    handleDifficultyFilterChange,
    open,
    muscleGroups,
    selectedMuscleGroups,
    selectedEquipment,
    eqipment,
    selectedDifficulty,
    difficulty,
  } = props

  const handleClose = () => {
    onClose()
  }

  return (
    <Dialog onClose={handleClose} open={open} fullWidth>
      <DialogTitle>Filters</DialogTitle>
      <DialogContent>
        <Stack direction="column" spacing={2} marginTop={1}>
          <FormControl fullWidth>
            <InputLabel id="muscle-group-label" size="small">
              Muscle Groups
            </InputLabel>
            <Select
              id="muscle-group-filter"
              labelId="muscle-group-label"
              size="small"
              multiple
              MenuProps={{
                style: {
                  maxHeight: "90vh",
                },
              }}
              value={selectedMuscleGroups}
              onChange={(e) => {
                const value = e.target.value as string[]
                handleMuscleGroupsFilterChange(value)
              }}
              input={
                <OutlinedInput
                  id="muscle-groups-chip"
                  label="Muscle Groups"
                  startAdornment={
                    selectedMuscleGroups.length > 0 ? (
                      <InputAdornment position="start">
                        <IconButton
                          edge="start"
                          size="small"
                          onClick={() => handleMuscleGroupsFilterChange([])}
                        >
                          <Close fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    ) : null
                  }
                />
              }
              renderValue={(selected: string[]) => (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    gap: 0.5,
                  }}
                >
                  {selected.map((value) => (
                    <Chip key={value} label={value} size="small" />
                  ))}
                </Box>
              )}
            >
              {muscleGroups.map((muscleGroup) => (
                <MenuItem key={muscleGroup} value={muscleGroup}>
                  {muscleGroup}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="equipment-label" size="small">
              Equipment
            </InputLabel>
            <Select
              id="equipment-filter"
              labelId="equipment-label"
              size="small"
              multiple
              MenuProps={{
                style: {
                  maxHeight: "90vh",
                },
              }}
              value={selectedEquipment}
              onChange={(e) => {
                const value = e.target.value as string[]
                handleEquipmentFilterChange(value)
              }}
              input={
                <OutlinedInput
                  id="equipment-chip"
                  label="Equipment"
                  startAdornment={
                    selectedEquipment.length > 0 ? (
                      <InputAdornment position="start">
                        <IconButton
                          edge="start"
                          size="small"
                          onClick={() => handleEquipmentFilterChange([])}
                        >
                          <Close fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    ) : null
                  }
                />
              }
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {" "}
                  {selected.map((value) => (
                    <Chip key={value} label={value} size="small" />
                  ))}
                </Box>
              )}
            >
              {eqipment.map((piece) => (
                <MenuItem key={piece} value={piece}>
                  {piece}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="difficulty-label" size="small">
              Difficulty
            </InputLabel>
            <Select
              id="difficulty-filter"
              labelId="difficulty-label"
              size="small"
              multiple
              MenuProps={{
                style: {
                  maxHeight: "90vh",
                },
              }}
              value={selectedDifficulty}
              onChange={(e) => {
                const value = e.target.value as string[]
                handleDifficultyFilterChange(value)
              }}
              input={
                <OutlinedInput
                  id="difficulty-chip"
                  label="Difficulty"
                  startAdornment={
                    selectedDifficulty.length > 0 ? (
                      <InputAdornment position="start">
                        <IconButton
                          edge="start"
                          size="small"
                          onClick={() => handleDifficultyFilterChange([])}
                        >
                          <Close fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    ) : null
                  }
                />
              }
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {" "}
                  {selected.map((value) => (
                    <Chip key={value} label={value} size="small" />
                  ))}
                </Box>
              )}
            >
              {difficulty.map((level) => (
                <MenuItem key={level} value={level}>
                  {level}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}

export default FilterDialog
