import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import { Link, matchPath, useLocation } from "react-router-dom"
import { useWindowSize } from "../utils/utils"
import { Paper } from "@mui/material"
import { useAppDispatch } from "../app/hooks"
import { logout } from "../features/appSlice"

function useRouteMatch(patterns: readonly string[]) {
  const { pathname } = useLocation()

  for (let i = 0; i < patterns.length; i += 1) {
    const pattern = patterns[i]
    const possibleMatch = matchPath(pattern, pathname)
    if (possibleMatch !== null) {
      return possibleMatch
    }
  }

  return null
}

type Props = {}

const BottomTabs = (props: Props) => {
  const size = useWindowSize()
  const routeMatch = useRouteMatch(["/"])
  const currentTab = routeMatch?.pattern?.path

  const dispatch = useAppDispatch()

  return (
    <Paper
      sx={{
        position:
          size.width != undefined && size.width > 600 ? "relative" : "fixed",
        bottom: size.width != undefined && size.width > 600 ? 45 : 0,
        left: 0,
        right: 0,
        zIndex: 1,
      }}
      elevation={3}
    >
      <Tabs value={currentTab ? currentTab : false} centered={true}>
        <Tab label="Workouts" value="/" to="/" component={Link} />
        <Tab
          label="Logout"
          onClick={() => {
            dispatch(logout())
          }}
        />
      </Tabs>
    </Paper>
  )
}

export default BottomTabs
