import { useEffect, useState } from "react";
import { IExercise, IExerciseRecord, IWorkout, IWorkoutRecord } from "../@types/workout";

interface WindowSize {
    width: number | undefined;
    height: number | undefined;
}

export const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState<WindowSize>({
        width: undefined,
        height: undefined
    });

    useEffect(() => {
        if (typeof window !== "undefined") {
            function handleResize() {
                setWindowSize({
                    width: window.innerWidth,
                    height: window.innerHeight
                });
            }

            window.addEventListener("resize", handleResize);

            handleResize();

            return () => window.removeEventListener("resize", handleResize);
        }
    }, []);
    return windowSize;
};


type ConditonalWrapperProps = {
    children: React.ReactElement
    condition: boolean
    wrapper: (children: React.ReactElement) => JSX.Element
};

export const ConditonalWrapper: React.FC<ConditonalWrapperProps> = ({
    condition,
    wrapper,
    children,
}) => (condition ? wrapper(children) : children)

export const reorder = <T>(
    list: T[],
    startIndex: number,
    endIndex: number
): T[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

export const delay = (ms: number) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export const findBestRecord = (records: IExerciseRecord[]): IExerciseRecord | undefined => {
    if (records.length === 0) {
        return undefined;
    }

    let bestRecord: IExerciseRecord = records[0];
    let bestRecordValue: number = (records[0].weight ?? 0) * (records[0].reps ?? 0);

    for (let record of records) {
        let recordValue: number = (record.weight ?? 0) * (record.reps ?? 0);

        if (recordValue > bestRecordValue) {
            bestRecord = record;
            bestRecordValue = recordValue;
        }
    }
    return bestRecord;
}

export const findLatestCompletedExerciseRecord = (records: IExerciseRecord[]): IExerciseRecord | undefined => {
    let latestCompletedRecord: IExerciseRecord | undefined = undefined;
    let latestCompletedTimestamp: number | null = null;

    for (let record of records) {
        if (record.completedAt) {
            let completedTimestamp = record.completedAt;
            if (latestCompletedTimestamp === null || completedTimestamp > latestCompletedTimestamp) {
                latestCompletedRecord = record;
                latestCompletedTimestamp = completedTimestamp;
            }
        }
    }

    return latestCompletedRecord;
}

export function findLatestCompletedWorkoutRecord(records: IWorkoutRecord[]): IWorkoutRecord | undefined {
    let latestCompletedRecord: IWorkoutRecord | undefined = undefined;
    let latestCompletedTimestamp: number | null = null;

    for (let record of records) {
        if (record.completedAt) {
            let completedTimestamp = record.completedAt;
            if (latestCompletedTimestamp === null || completedTimestamp > latestCompletedTimestamp) {
                latestCompletedRecord = record;
                latestCompletedTimestamp = completedTimestamp;
            }
        }
    }

    return latestCompletedRecord;
}

export function sortWorkouts(a: IWorkout, b: IWorkout): number {
    // If 'disabled' property is not defined, consider it as false
    const aDisabled = a.disabled ?? false
    const bDisabled = b.disabled ?? false

    // If both 'disabled' properties are the same, compare by 'last_completed_at'
    if (aDisabled === bDisabled) {
        const aCompletedAt = a.lastCompletedAt ?? Number.MIN_SAFE_INTEGER
        const bCompletedAt = b.lastCompletedAt ?? Number.MIN_SAFE_INTEGER

        return aCompletedAt - bCompletedAt
    }

    // If 'disabled' properties are different, sort so that non-disabled items come first
    if (aDisabled) {
        return 1
    } else {
        return -1
    }
}

export function addEquatableExercises(exercises: IExercise[]): IExercise[] {
    // For each exercise, find all exercises that have the same muscle groups and update its equatableExercises property
    return exercises.map(exercise => {
        const matchingExercises = exercises.filter(otherExercise => {
            // We don't want to compare the exercise with itself
            if (otherExercise.id === exercise.id) return false;

            // Check if they share the same muscle groups
            return JSON.stringify(otherExercise.muscleGroups.sort()) === JSON.stringify(exercise.muscleGroups.sort()) && otherExercise.difficulty === exercise.difficulty;
        });
        const equatableIds = matchingExercises.map(e => e.id);
        // If equatableExercises is already defined, we merge its values with the new ones and remove duplicates
        const mergedEquatable = Array.from(new Set([...(exercise.equatableExercises || []), ...equatableIds]));

        return {
            ...exercise,
            equatableExercises: mergedEquatable
        };
    });
}

export function replaceWithEquatableExercise(index: number, exercises: IExercise[], workout: IWorkout): IWorkout {
    // Clone the workout to not mutate the original object
    const newWorkout: IWorkout = { ...workout, exercises: [...workout.exercises] };

    // Check if the given index is valid
    if (index < 0 || index >= newWorkout.exercises.length) {
        throw new Error('Invalid index.');
    }

    const targetExercise = newWorkout.exercises[index];

    // Ensure that there are equatable exercises to choose from
    if (!targetExercise.equatableExercises || targetExercise.equatableExercises.length === 0) {
        throw new Error('No equatable exercises available for the selected exercise.');
    }

    // Select a random equatable exercise
    const randomEquatableExerciseId = targetExercise.equatableExercises[Math.floor(Math.random() * targetExercise.equatableExercises.length)];

    // Find that exercise in the provided exercises array
    const replacementExercise = exercises.find(e => e.id === randomEquatableExerciseId);

    // If the replacement exercise isn't found in the provided array, throw an error
    if (!replacementExercise) {
        throw new Error('Replacement exercise not found in the provided array.');
    }

    // Replace the old exercise with the new one in the cloned workout
    newWorkout.exercises[index] = replacementExercise;

    return newWorkout;
}

export function hydrateWorkout(
    workout: IWorkout,
    exerciseRecords: IExerciseRecord[],
    exercises: IExercise[]
): IWorkout {
    let newWorkout = { ...workout };

    const workoutExerciseIds = workout.exercises.map(e => e.id);
    newWorkout.exercises = workoutExerciseIds
        .map(workoutExerciseId => exercises.find(exercise => exercise.id === workoutExerciseId))
        .filter((exercise): exercise is IExercise => exercise !== undefined);

    const relatedExerciseRecords = exerciseRecords.filter(record => record.workoutID === workout.id);

    newWorkout.records = relatedExerciseRecords.map(record => ({
        id: record.id,
        workoutID: workout.id!,
        exerciseRecords: relatedExerciseRecords,
        completedAt: record.completedAt
    }));

    if (relatedExerciseRecords.length === 0) {
        return newWorkout;
    }

    newWorkout.lastCompletedAt = Math.max(
        ...relatedExerciseRecords.map(record => record.completedAt || 0)
    );
    return newWorkout;
}

export function hydrateWorkouts(workouts: IWorkout[], exerciseRecords: IExerciseRecord[], exercises: IExercise[]): IWorkout[] {
    return workouts.map(workout => hydrateWorkout(workout, exerciseRecords, exercises));
}

export function hydrateExercises(exercises: IExercise[], exerciseRecords: IExerciseRecord[]): IExercise[] {
    return exercises.map(exercise => {
        const records = exerciseRecords.filter(er => er.exerciseID === exercise.id);
        return {
            ...exercise,
            bestRecord: findBestRecord(records),
            lastRecord: findLatestCompletedExerciseRecord(records)
        }
    });
}