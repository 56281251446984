import { Draggable } from "react-beautiful-dnd"

import { IExercise } from "../@types/workout"
import { Paper } from "@mui/material"
import ExerciseCardList from "./ExerciseCard/ExerciseCardList"

export type Props = {
  item: IExercise
  idx: number
  handleRemoveExercise: (idx: number) => void
}

const DraggableListItem = ({ item, idx, handleRemoveExercise }: Props) => {
  return (
    <Draggable draggableId={item.id} index={idx}>
      {(provided, snapshot) => (
        <Paper
          elevation={snapshot.isDragging ? 4 : 1}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          sx={{
            marginBottom: 1,
            minWidth: 350,
          }}
        >
          <ExerciseCardList
            exercise={item}
            idx={idx}
            handleRemove={handleRemoveExercise}
          />
        </Paper>
      )}
    </Draggable>
  )
}

export default DraggableListItem
