
import { initializeApp } from 'firebase/app';
import {
    getAuth,
    onAuthStateChanged,
    signInAnonymously,
    signInWithPopup,
    signOut,
    GoogleAuthProvider,
    connectAuthEmulator,
} from 'firebase/auth';
import { getFirestore, collection, getDocs, connectFirestoreEmulator } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyA7LXvDhQOzOFK0lfMpxvmtccwSDh-3pzY",
    authDomain: "fitness-5bac6.firebaseapp.com",
    projectId: "fitness-5bac6",
    storageBucket: "fitness-5bac6.appspot.com",
    messagingSenderId: "873057214834",
    appId: "1:873057214834:web:870ac563f1783071d57deb",
    measurementId: "G-9WZ2GG314S"
};
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

if (location.hostname === '127.0.0.1' || location.hostname === 'localhost') {
    connectAuthEmulator(auth, 'http://localhost:9099', { disableWarnings: true });
    connectFirestoreEmulator(db, 'localhost', 8080);
}

export {
    auth,
    db,
    onAuthStateChanged,
    signInAnonymously,
    signInWithPopup,
    signOut,
    collection,
    getDocs,
    GoogleAuthProvider,
}
