import { Box, Container, Grid, IconButton, Tooltip } from "@mui/material"
import ExerciseCardWorkout from "../components/ExerciseCard/ExerciseCardWorkout"
import { IWorkoutRecord } from "../@types/workout"
import { useEffect, useState } from "react"
import { recordWorkout, selectApp, swapExercises } from "../features/appSlice"
import { useNavigate } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../app/hooks"
import { CheckCircle } from "@mui/icons-material"

type Props = {}

function Workout(props: Props) {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const workout = useAppSelector(selectApp).currentWorkout!

  const [record, setRecord] = useState<IWorkoutRecord>({
    workoutID: workout.id!, // TODO: can we guarantee this?
    exerciseRecords: workout.exercises.map((exercise) => ({
      exerciseID: exercise.id,
      workoutID: workout.id!,
      weight: exercise.lastRecord?.weight ?? 0,
      reps: exercise.lastRecord?.reps ?? 0,
      satisfied: undefined,
    })),
  })

  useEffect(() => {
    if (!workout.id) navigate("/")
    const newRecord = { ...record }
    newRecord.exerciseRecords = workout.exercises.map((exercise) => ({
      exerciseID: exercise.id,
      workoutID: workout.id!,
      weight: exercise.lastRecord?.weight ?? 0,
      reps: exercise.lastRecord?.reps ?? 0,
      satisfied: undefined,
    }))
    setRecord(newRecord)
  }, [workout])

  const handleRepsChange = (idx: number, reps: number) => {
    const newRecord = { ...record }
    newRecord.exerciseRecords[idx].reps = reps
    setRecord(newRecord)
  }

  const handleWeightChange = (idx: number, weight: number) => {
    const newRecord = { ...record }
    newRecord.exerciseRecords[idx].weight = weight
    setRecord(newRecord)
  }

  const handleSatisfied = (idx: number) => {
    const newRecord = { ...record }
    if (record.exerciseRecords[idx].satisfied == true) {
      newRecord.exerciseRecords[idx].satisfied = undefined
    } else {
      newRecord.exerciseRecords[idx].satisfied = true
    }
    setRecord(newRecord)
  }

  const handleDissatisfied = (idx: number) => {
    const newRecord = { ...record }
    if (record.exerciseRecords[idx].satisfied == false) {
      newRecord.exerciseRecords[idx].satisfied = undefined
    } else {
      newRecord.exerciseRecords[idx].satisfied = false
    }
    setRecord(newRecord)
  }

  const handleSwap = (idx: number) => {
    dispatch(swapExercises(idx))
    const newRecord = { ...record }
    // First we reset the relevant exercise record, then we update the exercise id in the useEffect
    newRecord.exerciseRecords[idx].weight = 0
    newRecord.exerciseRecords[idx].reps = 0
    newRecord.exerciseRecords[idx].satisfied = undefined
    setRecord(newRecord)
  }

  const handleRecordWorkout = async () => {
    await dispatch(recordWorkout(record))
    navigate("/")
  }

  return (
    <Container sx={{ my: 3 }}>
      <Grid container spacing={2}>
        {workout.exercises.map((exercise, idx) => (
          <Grid item xs={12} key={idx}>
            <ExerciseCardWorkout
              idx={idx}
              exercise={exercise}
              record={record.exerciseRecords[idx]}
              handleWeightChange={handleWeightChange}
              handleRepsChange={handleRepsChange}
              handleSatisfied={handleSatisfied}
              handleDissatisfied={handleDissatisfied}
              handleSwap={handleSwap}
            />
          </Grid>
        ))}
      </Grid>
      <Box marginTop={5} marginBottom={20}>
        <Tooltip title="Record Workout">
          <span>
            <IconButton
              onClick={() => {
                handleRecordWorkout()
              }}
              style={{ margin: "0 auto", display: "flex" }}
            >
              <CheckCircle fontSize="large" />
            </IconButton>
          </span>
        </Tooltip>
      </Box>
    </Container>
  )
}

export default Workout
