import { IExercise } from "../../@types/workout"
import {
  Box,
  Card,
  CardContent,
  Chip,
  Grid,
  Stack,
  Typography,
} from "@mui/material"
import { Add } from "@mui/icons-material"
import VideoDialog from "../VideoDialog"
import { useState } from "react"

type Props = {
  idx: number
  exercise: IExercise
  handleAdd: (idx: number) => void
}

const ExerciseCardDetail = ({ idx, exercise, handleAdd }: Props) => {
  const [videoOpen, setVideoOpen] = useState<boolean>(false)

  return (
    <>
      <Card
        sx={{
          width: 350,
        }}
      >
        <CardContent>
          <Stack direction="row" spacing={1} alignItems="center">
            <Box onClick={() => setVideoOpen(true)} sx={{ cursor: "pointer" }}>
              <Typography variant="h6">{exercise.title}</Typography>
              <Grid container spacing={1} style={{ paddingTop: "8px" }}>
                {exercise.muscleGroups.map((group) => (
                  <Grid item key={group}>
                    <Chip label={group} size="small" />
                  </Grid>
                ))}

                {exercise.equipment.map((equipment) => (
                  <Grid item key={equipment}>
                    <Chip label={equipment} size="small" color="info" />
                  </Grid>
                ))}

                <Grid item>
                  <Chip
                    label={exercise.difficulty}
                    size="small"
                    color="warning"
                  />
                </Grid>
              </Grid>
            </Box>
            <Box component="div" sx={{ flexGrow: 1 }} />
            <Add onClick={() => handleAdd(idx)} sx={{ cursor: "pointer" }} />
          </Stack>
          <Stack direction="row" spacing={4} paddingTop={2}>
            {exercise.lastRecord !== undefined ? (
              <Stack direction="column">
                <Typography variant="caption">
                  <b>Last</b>
                </Typography>
                <Typography variant="caption">
                  {exercise.lastRecord?.weight !== undefined &&
                  exercise.lastRecord?.weight > 0
                    ? exercise.lastRecord?.weight + "kg / "
                    : null}{" "}
                  {exercise.lastRecord?.reps} reps
                </Typography>
              </Stack>
            ) : null}
            {exercise.bestRecord !== undefined ? (
              <Stack direction="column">
                <Typography variant="caption">
                  <b>Best</b>
                </Typography>
                <Typography variant="caption">
                  {exercise.bestRecord?.weight !== undefined &&
                  exercise.bestRecord?.weight > 0
                    ? exercise.bestRecord?.weight + "kg / "
                    : null}{" "}
                  {exercise.bestRecord?.reps} reps
                </Typography>
              </Stack>
            ) : null}
            {exercise.lastRecord !== undefined ? (
              <Stack direction="column">
                <Typography variant="caption">
                  <b>On</b>
                </Typography>
                <Typography variant="caption">
                  {new Date(
                    exercise.lastRecord!.completedAt!,
                  ).toLocaleDateString()}
                </Typography>
              </Stack>
            ) : (
              <Typography variant="caption">No Records Yet</Typography>
            )}
          </Stack>
        </CardContent>
      </Card>
      <VideoDialog
        video={exercise.videos[0]}
        open={videoOpen}
        onClose={() => setVideoOpen(false)}
      />
    </>
  )
}

export default ExerciseCardDetail
