import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../app/hooks"
import {
  Autocomplete,
  IconButton,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material"
import { IExercise } from "../@types/workout"
import ExerciseCardDetail from "../components/ExerciseCard/ExerciseCardDetail"
import DraggableList from "../components/DraggableList"
import { DropResult } from "react-beautiful-dnd"
import { reorder } from "../utils/utils"
import { useNavigate } from "react-router-dom"
import FilterDialog from "../components/FilterDialog"

import {
  AddCircle,
  CheckCircle,
  Delete,
  DoNotDisturbOff,
  FilterList,
  RunCircle,
} from "@mui/icons-material"
import {
  selectApp,
  addWorkout,
  updateWorkout,
  deleteWorkout,
} from "../features/appSlice"

type Props = {}

type Filters = {
  muscleGroups: string[]
  equipment: string[]
  category: string[]
  difficulty: string[]
}

function WorkoutDetail(props: Props) {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const app = useAppSelector(selectApp)

  const [title, setTitle] = useState<string>("")
  const [highlightedExercise, setHighlightedExercise] =
    useState<IExercise | null>(null)
  const [selectedExercises, setSelectedExercises] = useState<IExercise[]>([])
  const [filteredExercises, setFilteredExercises] = useState<IExercise[]>(
    app.coreData.exercises,
  )
  const [selectedFilters, setSelectedFilters] = useState<Filters>({
    muscleGroups: [],
    equipment: [],
    category: [],
    difficulty: [],
  })

  const [filtersOpen, setFiltersOpen] = useState<boolean>(false)

  useEffect(() => {
    if (app.currentWorkout) {
      setTitle(app.currentWorkout.title)
      setSelectedExercises(app.currentWorkout.exercises)
    }
  }, [app.currentWorkout])

  const handleAddExercise = () => {
    if (highlightedExercise !== null) {
      setSelectedExercises([...selectedExercises, highlightedExercise])
      setHighlightedExercise(null)
    }
  }

  const handleRemoveExercise = (idx: number) => {
    setSelectedExercises(selectedExercises.filter((_, index) => index !== idx))
  }

  const onDragEnd = ({ destination, source }: DropResult) => {
    if (!destination) return

    const newItems = reorder(
      selectedExercises,
      source.index,
      destination.index,
    ) as unknown as IExercise[]

    setSelectedExercises(newItems)
  }

  const handleAddWorkout = () => {
    dispatch(
      addWorkout({
        title: title,
        exercises: selectedExercises,
        records: [],
      }),
    ).then(() => {
      navigate("/")
    })
  }

  const handleUpdateWorkout = async () => {
    await dispatch(
      updateWorkout({
        id: app.currentWorkout!.id,
        title: title,
        exercises: selectedExercises,
        records: app.currentWorkout!.records,
      }),
    )
    navigate("/")
  }

  const handleDeleteWorkout = async () => {
    if (app.currentWorkout && app.currentWorkout.id) {
      await dispatch(deleteWorkout(app.currentWorkout.id))
      navigate("/")
    }
  }

  const handleToggle = async (disabled: boolean) => {
    if (!app.currentWorkout) {
      return
    }
    let newWorkout = { ...app.currentWorkout }
    newWorkout.disabled = disabled
    await dispatch(updateWorkout(newWorkout))
    navigate("/")
  }

  const handleMuscleGroupsFilterChange = (value: string[]) => {
    const newFilters = { ...selectedFilters, muscleGroups: value }
    setSelectedFilters(newFilters)
    setFilteredExercises(filterExercises(app.coreData.exercises, newFilters))
  }

  const handleEqiupmentFilterChange = (value: string[]) => {
    const newFilters = { ...selectedFilters, equipment: value }
    setSelectedFilters(newFilters)
    setFilteredExercises(filterExercises(app.coreData.exercises, newFilters))
  }

  const handleDifficultyFilterChange = (value: string[]) => {
    const newFilters = { ...selectedFilters, difficulty: value }
    setSelectedFilters(newFilters)
    setFilteredExercises(filterExercises(app.coreData.exercises, newFilters))
  }

  const filterExercises = (
    exercises: IExercise[],
    filters: Filters,
  ): IExercise[] => {
    return exercises.filter((exercise) => {
      if (
        filters.muscleGroups.length > 0 &&
        !filters.muscleGroups.some((group) =>
          exercise.muscleGroups.includes(group),
        )
      ) {
        return false
      }

      if (
        filters.equipment.length > 0 &&
        !filters.equipment.some((equip) => exercise.equipment.includes(equip))
      ) {
        return false
      }

      if (
        filters.category.length > 0 &&
        !filters.category.includes(exercise.category)
      ) {
        return false
      }

      if (
        filters.difficulty.length > 0 &&
        (!exercise.difficulty ||
          !filters.difficulty.includes(exercise.difficulty))
      ) {
        return false
      }

      return true
    })
  }

  return (
    <>
      <Stack
        sx={{
          my: 5,
        }}
        alignItems="center"
        spacing={2}
      >
        <TextField
          value={title}
          label="Workout Title"
          onChange={(e) => setTitle(e.target.value)}
          size="small"
          sx={{ minWidth: 350 }}
        />
        <Autocomplete
          id="exercise-select"
          size="small"
          sx={{ minWidth: 350 }}
          options={filteredExercises}
          getOptionLabel={(option) => option.title}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Exercise"
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <span>
                    <IconButton
                      size="small"
                      onClick={() => {
                        setHighlightedExercise(null)
                        setFiltersOpen(true)
                      }}
                    >
                      <FilterList />
                    </IconButton>
                    {params.InputProps.startAdornment}
                  </span>
                ),
              }}
            />
          )}
          value={highlightedExercise}
          onChange={(_, value) => {
            setHighlightedExercise(value)
          }}
          getOptionDisabled={(option) => {
            return selectedExercises.map((e) => e.id).includes(option.id)
          }}
        />
        {highlightedExercise === null ? null : (
          <ExerciseCardDetail
            idx={0}
            exercise={highlightedExercise}
            handleAdd={handleAddExercise}
          />
        )}
        <DraggableList
          onDragEnd={onDragEnd}
          handleRemoveExercise={handleRemoveExercise}
          items={selectedExercises}
        />
        {app.currentWorkout ? (
          <Stack direction="row" spacing={6} paddingTop={1}>
            <Tooltip title="Update Workout">
              <span>
                <IconButton
                  onClick={() => handleUpdateWorkout()}
                  disabled={selectedExercises.length === 0 || title === ""}
                >
                  <CheckCircle fontSize="large" />
                </IconButton>
              </span>
            </Tooltip>
            {app.currentWorkout.disabled ? (
              <Tooltip title="Enable Workout">
                <span>
                  <IconButton
                    onClick={() => handleToggle(false)}
                    disabled={selectedExercises.length === 0 || title === ""}
                  >
                    <RunCircle fontSize="large" />
                  </IconButton>
                </span>
              </Tooltip>
            ) : (
              <Tooltip title="Disable Workout">
                <span>
                  <IconButton
                    onClick={() => handleToggle(true)}
                    disabled={selectedExercises.length === 0 || title === ""}
                  >
                    <DoNotDisturbOff fontSize="large" />
                  </IconButton>
                </span>
              </Tooltip>
            )}
            <Tooltip title="Delete Workout">
              <span>
                <IconButton
                  onClick={() => handleDeleteWorkout()}
                  disabled={selectedExercises.length === 0 || title === ""}
                >
                  <Delete fontSize="large" />
                </IconButton>
              </span>
            </Tooltip>
          </Stack>
        ) : (
          <Tooltip title="Add Workout">
            <span>
              <IconButton
                onClick={() => handleAddWorkout()}
                disabled={selectedExercises.length === 0 || title === ""}
              >
                <AddCircle fontSize="large" />
              </IconButton>
            </span>
          </Tooltip>
        )}
      </Stack>
      <FilterDialog
        open={filtersOpen}
        onClose={() => setFiltersOpen(false)}
        selectedMuscleGroups={selectedFilters.muscleGroups}
        muscleGroups={app.coreData.muscleGroups}
        selectedDifficulty={selectedFilters.difficulty}
        difficulty={app.coreData.difficulty}
        selectedEquipment={selectedFilters.equipment}
        eqipment={app.coreData.equipment}
        handleMuscleGroupsFilterChange={handleMuscleGroupsFilterChange}
        handleEquipmentFilterChange={handleEqiupmentFilterChange}
        handleDifficultyFilterChange={handleDifficultyFilterChange}
      />
    </>
  )
}

export default WorkoutDetail
