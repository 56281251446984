import { createTheme } from "@mui/material/styles";

declare module "@mui/material/styles" {
    interface Theme {
    }

    interface ThemeOptions { }
}

export const theme = createTheme({
    palette: {
        background: {
            default: "#D3D3D3",
        },
    },
});