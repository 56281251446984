import { Dialog, DialogContent } from "@mui/material"

export interface Props {
  open: boolean
  video: string
  onClose: () => void
}

function VideoDialog({ open, video, onClose }: Props) {
  return (
    <Dialog
      onClose={onClose}
      open={open}
      sx={{
        "& .MuiPaper-root": {
          margin: "4px",
          overflowY: "unset",
        },
        "& .MuiDialogContent-root": {
          padding: 0,
          margin: 0,
        },
      }}
    >
      <DialogContent>
        <video
          width="100%"
          height="auto"
          autoPlay
          loop
          playsInline
          style={{ display: "block", border: "none", margin: 0 }}
          src={video}
          typeof="video/mp4"
        ></video>
      </DialogContent>
    </Dialog>
  )
}

export default VideoDialog
