import {
  Box,
  Card,
  CardContent,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material"
import { Edit } from "@mui/icons-material"
import { IWorkout } from "../@types/workout"
import { useAppDispatch } from "../app/hooks"
import { useNavigate } from "react-router-dom"
import { setCurrentWorkout } from "../features/appSlice"

type Props = {
  workout: IWorkout
}

const WorkoutCardDetail = ({ workout }: Props) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const completedAt = workout.lastCompletedAt
  const displayText = completedAt
    ? new Date(completedAt).toLocaleDateString() +
      " " +
      new Date(completedAt).toLocaleTimeString()
    : "Never"

  const handleClicked = () => {
    dispatch(setCurrentWorkout(workout))
    navigate("/workout")
  }

  const handleEdit = () => {
    dispatch(setCurrentWorkout(workout))
    navigate("/workout-detail")
  }

  return (
    <Card>
      <CardContent>
        <Stack direction="row" spacing={1} alignItems="center">
          <Box onClick={() => handleClicked()} sx={{ cursor: "pointer" }}>
            <Typography
              variant="h6"
              color={workout.disabled ? "text.disabled" : "text.primary"}
            >
              {workout.title}
            </Typography>
            <Typography
              variant="caption"
              color={workout.disabled ? "text.disabled" : "text.secondary"}
            >
              <b>Last Completed:</b> {displayText}
            </Typography>
          </Box>
          <Box component="div" sx={{ flexGrow: 1 }} />
          <Tooltip title="Edit Workout">
            <IconButton onClick={() => handleEdit()}>
              <Edit />
            </IconButton>
          </Tooltip>
        </Stack>
      </CardContent>
    </Card>
  )
}

export default WorkoutCardDetail
