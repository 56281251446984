import { Chip, Grid, Stack, Typography } from "@mui/material"

type Props = {
  title: string
  muscleGroups: string[]
  equipment: string[]
  difficulty?: string
}

const ExerciseCardHeader = (props: Props) => {
  return (
    <Stack direction="column" spacing={1}>
      <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
        {props.title}
      </Typography>
      <Grid container spacing={1} style={{ paddingTop: "8px" }}>
        {props.muscleGroups.map((group) => (
          <Grid item key={group}>
            <Chip label={group} size="small" />
          </Grid>
        ))}
        {props.equipment.map((equipment) => (
          <Grid item key={equipment}>
            <Chip label={equipment} size="small" color="info" />
          </Grid>
        ))}
        {props.difficulty ? (
          <Grid item>
            <Chip label={props.difficulty} size="small" color="warning" />
          </Grid>
        ) : null}
      </Grid>
    </Stack>
  )
}

export default ExerciseCardHeader
