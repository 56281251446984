import {
  Button,
  Card,
  CardContent,
  FormControl,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material"

import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied"
import SentimentSatisfiedIcon from "@mui/icons-material/SentimentSatisfied"
import { Box } from "@mui/material"
import ExerciseCardHeader from "./ExerciseCardHeader"
import { IExercise, IExerciseRecord } from "../../@types/workout"
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle"
import { useState } from "react"
import VideoDialog from "../VideoDialog"
import { PlayCircle } from "@mui/icons-material"

interface ExerciseCardProps {
  idx: number
  exercise: IExercise
  record: IExerciseRecord
  handleRepsChange: (idx: number, reps: number) => void
  handleWeightChange: (idx: number, weight: number) => void
  handleSatisfied: (idx: number) => void
  handleDissatisfied: (idx: number) => void
  handleSwap: (idx: number) => void
}

const ExerciseCardWorkout = ({
  idx,
  exercise,
  record,
  handleWeightChange,
  handleRepsChange,
  handleSatisfied,
  handleDissatisfied,
  handleSwap,
}: ExerciseCardProps) => {
  const [videoOpen, setVideoOpen] = useState<boolean>(false)

  return (
    <>
      <Card sx={{ minWidth: 275 }}>
        <CardContent>
          <ExerciseCardHeader
            title={exercise.title}
            muscleGroups={exercise.muscleGroups}
            equipment={exercise.equipment}
            difficulty={exercise.difficulty}
          />
          <Stack
            direction="row"
            justifyContent="center"
            sx={{
              my: 1,
            }}
          >
            {exercise.hasWeight ? (
              <FormControl sx={{ m: 1, width: 1 }}>
                <TextField
                  id="weight"
                  label="Weight"
                  type="number"
                  size="small"
                  value={record.weight === 0 ? "" : record.weight}
                  onChange={(e) => handleWeightChange(idx, +e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">kg</InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            ) : null}
            <FormControl sx={{ m: 1, width: 1 }} size="small">
              <TextField
                id="reps"
                label="Reps"
                type="number"
                size="small"
                value={record.reps === 0 ? "" : record.reps}
                onChange={(e) => handleRepsChange(idx, +e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <Typography variant="caption"></Typography>
            </FormControl>
          </Stack>
          <Stack direction="row" spacing={2}>
            <Typography variant="caption">
              <>
                {exercise.lastRecord !== undefined ? (
                  <>
                    <b>Last</b>:{" "}
                    {exercise.hasWeight
                      ? exercise.lastRecord.weight + "kg / "
                      : null}
                    {exercise.lastRecord.reps} reps
                  </>
                ) : (
                  <>No Records Yet</>
                )}
              </>
            </Typography>
            <Typography variant="caption">
              <>
                {exercise.bestRecord?.reps ? (
                  <>
                    <b>Best</b>:{" "}
                    {exercise.hasWeight
                      ? exercise.bestRecord.weight + "kg / "
                      : null}
                    {exercise.bestRecord.reps} reps
                  </>
                ) : null}
              </>
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={2} marginTop={1}>
            <Tooltip title="Swap Workout">
              <span>
                <IconButton
                  onClick={() => handleSwap(idx)}
                  disabled={exercise.equatableExercises === undefined}
                >
                  <ChangeCircleIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Watch Video">
              <span>
                <IconButton onClick={() => setVideoOpen(true)}>
                  <PlayCircle />
                </IconButton>
              </span>
            </Tooltip>
            <Box component="div" sx={{ flexGrow: 1 }} />
            <IconButton onClick={() => handleDissatisfied(idx)}>
              <SentimentDissatisfiedIcon
                color={
                  record.satisfied == false || record.satisfied === undefined
                    ? "error"
                    : "disabled"
                }
              />
            </IconButton>
            <IconButton onClick={() => handleSatisfied(idx)}>
              <SentimentSatisfiedIcon
                color={
                  record.satisfied == true || record.satisfied === undefined
                    ? "success"
                    : "disabled"
                }
              />
            </IconButton>
          </Stack>
        </CardContent>
      </Card>
      <VideoDialog
        video={exercise.videos[0]}
        open={videoOpen}
        onClose={() => setVideoOpen(false)}
      />
    </>
  )
}

export default ExerciseCardWorkout
