import { Grid, Typography } from "@mui/material"

type Props = {}

const NotFound = (props: Props) => {
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Grid item xs={12} padding={2}>
        <Typography variant="h1">404</Typography>
      </Grid>
    </Grid>
  )
}

export default NotFound
