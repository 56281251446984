import * as React from "react"
import DraggableListItem from "./DraggableListItem"
import {
  DragDropContext,
  Droppable,
  OnDragEndResponder,
} from "react-beautiful-dnd"
import { IExercise } from "../@types/workout"

export type DraggableListProps = {
  items: IExercise[]
  onDragEnd: OnDragEndResponder
  handleRemoveExercise: (idx: number) => void
}

const DraggableList = React.memo(
  ({ items, onDragEnd, handleRemoveExercise }: DraggableListProps) => {
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable-list">
          {(provided: any) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {items.map((item, idx) => (
                <DraggableListItem
                  item={item}
                  idx={idx}
                  key={item.id}
                  handleRemoveExercise={handleRemoveExercise}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    )
  },
)

export default DraggableList
