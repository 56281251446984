import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Tooltip,
} from "@mui/material"
import WorkoutCardDetail from "../components/WorkoutCardDetail"
import { useAppDispatch, useAppSelector } from "../app/hooks"
import { useNavigate } from "react-router-dom"
import { useEffect } from "react"
import { selectApp, setCurrentWorkout } from "../features/appSlice"
import { AddCircle } from "@mui/icons-material"

type Props = {}

function Workout(props: Props) {
  const app = useAppSelector(selectApp)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(setCurrentWorkout(null))
  }, [])

  return (
    <Container sx={{ my: 3 }}>
      <Grid container spacing={2}>
        {app.workouts.map((workout, idx) => (
          <Grid item xs={12} md={6} key={idx}>
            <WorkoutCardDetail workout={workout} />
          </Grid>
        ))}
      </Grid>
      <Box sx={{ height: 100, my: 2 }}>
        <Tooltip title="Add Workout">
          <span>
            <IconButton
              onClick={() => {
                navigate("/workout-detail")
              }}
              style={{ margin: "0 auto", display: "flex" }}
            >
              <AddCircle fontSize="large" />
            </IconButton>
          </span>
        </Tooltip>
      </Box>
    </Container>
  )
}

export default Workout
