import { Skeleton } from "@mui/material"
import { Navigate, useLocation } from "react-router"
import { useAppSelector } from "../app/hooks"
import { selectApp } from "../features/appSlice"

interface Props {
  children: JSX.Element
}

const ProtectedRoute = ({ children }: Props): JSX.Element => {
  const location = useLocation()
  const user = useAppSelector(selectApp)

  return (
    <>
      {user.user ? (
        children
      ) : (
        <Navigate to="/login" replace state={{ from: location }} />
      )}
    </>
  )
}

export default ProtectedRoute
