import { Box, Button } from "@mui/material"
import { useAppDispatch, useAppSelector } from "../app/hooks"
import { selectApp, signInWithGoogle } from "../features/appSlice"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

type Props = {}

const Login = (props: Props) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const user = useAppSelector(selectApp)
  useEffect(() => {
    if (user.user) {
      navigate("/")
    }
  }, [user])

  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Button
        size="large"
        variant="contained"
        disabled={!user || user.state === "loading"}
        onClick={() => dispatch(signInWithGoogle())}
      >
        Sign In With Google
      </Button>
    </Box>
  )
}

export default Login
