import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import { auth, signOut, signInWithPopup, GoogleAuthProvider, collection, db } from '../app/firebase';
import { IUser } from '../@types/user';
import { IExercise, IExerciseRecord, IWorkout, IWorkoutRecord } from '../@types/workout';
import { addDoc, deleteDoc, doc, getDocs, updateDoc } from 'firebase/firestore';
import { addEquatableExercises, hydrateExercises, hydrateWorkouts, replaceWithEquatableExercise, sortWorkouts } from '../utils/utils';

export interface AppState {
    user?: IUser;
    workouts: IWorkout[];
    exerciseRecords: IExerciseRecord[];
    currentWorkout?: IWorkout;
    coreData: CoreData;
    state: 'idle' | 'loading' | 'failed';
}

export interface CoreData {
    exercises: IExercise[];
    muscleGroups: string[];
    equipment: string[];
    difficulty: string[];
}

const initialState: AppState = {
    workouts: [],
    exerciseRecords: [],
    coreData: {} as CoreData,
    state: 'loading',
}

export const initializeCoreData = createAsyncThunk(
    'app/initializeCoreData',
    async () => {
        const codeDataCollection = collection(db, 'coreData');
        const codeDataSnapshot = await getDocs(codeDataCollection);
        if (codeDataSnapshot.empty) {
            throw new Error('No core data found');
        }
        if (codeDataSnapshot.size > 1) {
            throw new Error('More than one core data document found');
        }
        const doc = codeDataSnapshot.docs[0].data();
        doc.exercises = addEquatableExercises(doc.exercises);

        doc.exercises = doc.exercises.sort((a: IExercise, b: IExercise) => {
            if (a.title < b.title) {
                return -1;
            }
            if (a.title > b.title) {
                return 1
            }
            return 0;
        });

        doc.muscleGroups = doc.muscleGroups.sort((a: string, b: string) => a.localeCompare(b));
        doc.equipment = doc.equipment.sort((a: string, b: string) => a.localeCompare(b));

        return doc as CoreData;
    }
)

export const addWorkout = createAsyncThunk(
    'app/addWorkout',
    async (workout: IWorkout, thunkAPI) => {
        const state = thunkAPI.getState() as RootState;
        const uid = state.app.user?.id;
        const doc = {
            title: workout.title,
            exercises: workout.exercises.map((exercise: IExercise) => {
                return {
                    id: exercise.id,
                }
            }),
        }
        const workoutCollection = collection(db, `users/${uid}/workouts`);
        await addDoc(workoutCollection, doc);
    }
);

export const updateWorkout = createAsyncThunk(
    'app/updateWorkout',
    async (workout: IWorkout, thunkAPI) => {
        const state = thunkAPI.getState() as RootState;
        const uid = state.app.user?.id;
        const workoutsCollection = collection(db, `users/${uid}/workouts`);
        const docRef = doc(workoutsCollection, workout.id);
        const d: any = {
            title: workout.title,
            exercises: workout.exercises.map((exercise: IExercise) => {
                return {
                    id: exercise.id,
                }
            }),
        }
        if (workout.disabled !== undefined) {
            d.disabled = workout.disabled;
        }
        if (workout.lastCompletedAt) {
            d.lastCompletedAt = workout.lastCompletedAt;
        }
        await updateDoc(docRef, d);
    }
);

export const deleteWorkout = createAsyncThunk(
    'app/deleteWorkout',
    async (workoutID: string, thunkAPI) => {
        const state = thunkAPI.getState() as RootState;
        const uid = state.app.user?.id;
        const workoutsCollection = collection(db, `users/${uid}/workouts`);
        const docRef = doc(workoutsCollection, workoutID);
        await deleteDoc(docRef);
    }
);

export const recordWorkout = createAsyncThunk(
    'app/recordWorkout',
    async (workoutRecord: IWorkoutRecord, thunkAPI) => {
        const state = thunkAPI.getState() as RootState;
        const uid = state.app.user?.id;
        const exerciseRecordsCollection = collection(db, `users/${uid}/exerciseRecords`);

        for (const exerciseRecord of workoutRecord.exerciseRecords) {
            const doc: any = {
                exerciseID: exerciseRecord.exerciseID,
                workoutID: workoutRecord.workoutID,
                reps: exerciseRecord.reps ?? null,
                completedAt: new Date().getTime(),
            }
            if (exerciseRecord.weight) {
                doc.weight = exerciseRecord.weight;
            }
            if (exerciseRecord.satisfied !== undefined) {
                doc.satisfied = exerciseRecord.satisfied;
            }
            await addDoc(exerciseRecordsCollection, doc);
        }
    }
);

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        signInWithGoogle: (state) => {
            state.state = "loading";
            signInWithPopup(auth, new GoogleAuthProvider())
                .catch((error) => {
                    console.error(error)
                })
        },
        logout: () => {
            signOut(auth)
        },
        userAuthStateChanged: (state, action) => {
            if (!action.payload) {
                state.state = "idle";
                state.user = undefined;
                return;
            }
            const user = action.payload as IUser;
            state.user = user;
        },
        buildWorkouts: (state, action) => {
            const currentState = current(state);
            const payload = action.payload;

            const newWorkouts = payload.map((doc: any) => {
                const docExerciseIds = doc.data.exercises.map((e: any) => e.id) as string[];
                const filteredExercises = docExerciseIds
                    .map(docExerciseId => currentState.coreData.exercises.find(exercise => exercise.id === docExerciseId))
                    .filter(Boolean) as IExercise[];

                return {
                    id: doc.id,
                    title: doc.data.title,
                    exercises: filteredExercises,
                    disabled: doc.data.disabled ?? false,
                };
            }).sort(sortWorkouts);

            state.workouts = hydrateWorkouts(newWorkouts, currentState.exerciseRecords, currentState.coreData.exercises).sort(sortWorkouts);
        },
        buildExerciseRecords: (state, action) => {
            if (action.payload.empty) return;
            const exerciseRecords = action.payload.map((doc: any) => {
                return {
                    id: doc.id,
                    exerciseID: doc.data.exerciseID,
                    workoutID: doc.data.workoutID,
                    weight: doc.data.weight,
                    reps: doc.data.reps,
                    satisfied: doc.data.satisfied,
                    completedAt: doc.data.completedAt,
                } as IExerciseRecord;
            });
            state.exerciseRecords = exerciseRecords;

            const currentState = current(state);
            state.coreData.exercises = hydrateExercises(currentState.coreData.exercises, exerciseRecords);
            const currentState2 = current(state);
            state.workouts = hydrateWorkouts(currentState2.workouts, exerciseRecords, currentState2.coreData.exercises).sort(sortWorkouts);
        },
        setCurrentWorkout: (state, action) => {
            state.currentWorkout = action.payload;
        },
        swapExercises: (state, action) => {
            if (!state.currentWorkout) return;
            const currentState = current(state);
            const newWorkout = replaceWithEquatableExercise(action.payload, currentState.coreData.exercises, currentState.currentWorkout!);
            state.currentWorkout.exercises = newWorkout.exercises;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(initializeCoreData.pending, (state) => {
                state.state = 'loading';
            })
            .addCase(initializeCoreData.fulfilled, (state, action) => {
                state.coreData = action.payload;
                state.state = 'idle';
            })
            .addCase(initializeCoreData.rejected, (state) => {
                state.state = 'failed';
            })
            .addCase(addWorkout.pending, (state) => {
                state.state = 'loading';
            })
            .addCase(addWorkout.fulfilled, (state) => {
                state.state = 'idle';
            })
            .addCase(addWorkout.rejected, (state) => {
                state.state = 'failed';
            })
            .addCase(recordWorkout.pending, (state) => {
                state.state = 'loading';
            })
            .addCase(recordWorkout.fulfilled, (state, action) => {
                state.state = 'idle';
            })
            .addCase(recordWorkout.rejected, (state) => {
                state.state = 'failed';
            })
            .addCase(updateWorkout.pending, (state) => {
                state.state = 'loading';
            })
            .addCase(updateWorkout.fulfilled, (state, action) => {
                state.state = 'idle';
            })
            .addCase(updateWorkout.rejected, (state) => {
                state.state = 'failed';
            })
    }
});

export const {
    logout,
    userAuthStateChanged,
    signInWithGoogle,
    buildWorkouts,
    buildExerciseRecords,
    setCurrentWorkout,
    swapExercises
} = appSlice.actions;

export const selectApp = (state: RootState) => state.app;

export default appSlice.reducer;